import Vue from 'vue'
import VueI18n from 'vue-i18n'

import de from 'vuetify/lib/locale/de'
import en from 'vuetify/lib/locale/en'

Vue.use(VueI18n)

const messages = {
  de: {
    ...require('@/locales/de.json'),
    $vuetify: de,
  },
  en: {
    ...require('@/locales/en.json'),
    $vuetify: en,
  },
}

const hyphenIdx = navigator.language.indexOf('-')
let locale = ''
if (hyphenIdx === -1) {
  locale = navigator.language
} else {
  locale = navigator.language.substr(0, hyphenIdx)
}

export default new VueI18n({
  locale: locale || process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages,
})
