import { APIRequest, AuthRequest } from '@/services/api/auth'
import i18n from '@/i18n'

export const plans = {
  trial: {
    displayNameLocaleCode: 'display_name_plan_trial',
    properties: [
      i18n.t('free_forever'),
      i18n.t('one_vacancy_for_two_weeks'),
      i18n.t('all_features_available'),
    ],
  },
  test_wiab_v13: {
    displayNameLocaleCode: 'display_name_plan_basic_6',
    properties: [
      i18n.t('two_vacancies_per_month'),
      i18n.t('all_features_available'),
      i18n.t('plan_basic_6_price'),
    ],
  },
  basic_6: {
    displayNameLocaleCode: 'display_name_plan_basic_6',
    properties: [
      i18n.t('two_vacancies_per_month'),
      i18n.t('all_features_available'),
      i18n.t('plan_basic_6_price'),
    ],
  },
  basic_6_special: {
    displayNameLocaleCode: 'display_name_plan_basic_6_special',
    properties: [
      i18n.t('two_months_free'),
      i18n.t('two_vacancies_per_month'),
      i18n.t('all_features_available'),
      i18n.t('plan_basic_6_price'),
    ],
  },
  basic_12: {
    displayNameLocaleCode: 'display_name_plan_basic_12',
    properties: [
      i18n.t('two_vacancies_per_month'),
      i18n.t('all_features_available'),
      i18n.t('plan_basic_12_price'),
    ],
  },
  basic_12_special: {
    displayNameLocaleCode: 'display_name_plan_basic_12_special',
    properties: [
      i18n.t('two_months_free'),
      i18n.t('two_vacancies_per_month'),
      i18n.t('all_features_available'),
      i18n.t('plan_basic_12_price'),
    ],
  },
}

export const getPaymentInfo = (customerId) => {
  return new Promise((resolve, reject) => {
    APIRequest.get(`payment/customer/${customerId}/payment/`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getPlan = (plan) => {
  return new Promise((resolve, reject) => {
    APIRequest.get(`payment/plan/${plan}/`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const createPayment = (data) => {
  return new Promise((resolve, reject) => {
    AuthRequest.post('payment/', data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const prepareSubscription = (data) => {
  return new Promise((resolve, reject) => {
    APIRequest.post('payment/subscription/', data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const createSubscription = (data) => {
  return new Promise((resolve, reject) => {
    APIRequest.put('payment/subscription/', data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getExtraSubscriptionInfo = (subscriptionId) => {
  return new Promise((resolve, reject) => {
    AuthRequest.get(`payment/subscription/${subscriptionId}/`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const cancelSubscription = (subscriptionId) => {
  return new Promise((resolve, reject) => {
    AuthRequest.delete(`payment/subscription/${subscriptionId}/cancel/`)
      .then(() => {
        resolve(true)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const undoCancelSubscription = (subscriptionId) => {
  return new Promise((resolve, reject) => {
    AuthRequest.post(`payment/subscription/${subscriptionId}/cancel/`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
