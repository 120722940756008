import axios from 'axios'
import store from '@/store'

// this base url will be change based on
// if you need to point to production.
export const BASE_URL = process.env.VUE_APP_API_BASE_URI
export const ACCESS_TOKEN = 'wiab_access_token'
export const REFRESH_TOKEN = 'wiab_refresh_token'

export const getCookie = (cname) => {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export const APIRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

export const APIRequestFormData = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json',
  },
})

export const loginUser = (email, password) => {
  return new Promise((resolve, reject) => {
    APIRequest.post('clients/login/', { email, password })
      .then((response) => {
        window.localStorage.setItem(ACCESS_TOKEN, response.data.tokens.access)
        window.localStorage.setItem(REFRESH_TOKEN, response.data.tokens.refresh)
        updateBearerToken(response.data.tokens.access)
        resolve(response.data.client)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const checkValidRegistration = (data) => {
  return new Promise((resolve, reject) => {
    APIRequest.post('clients/register/check/', data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const registerApplicant = (data) => {
  return new Promise((resolve, reject) => {
    APIRequestFormData.post('clients/signup/', data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const registerEstablishment = (data) => {
  return new Promise((resolve, reject) => {
    APIRequestFormData.post('clients/register/', data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const refreshToken = () => {
  return new Promise((resolve) => {
    const refreshToken = window.localStorage.getItem(REFRESH_TOKEN)
    if (!refreshToken) {
      resolve('')
      return
    }
    const body = { refresh: refreshToken }
    APIRequest.post('token/refresh/', body)
      .then((response) => {
        window.localStorage.setItem(ACCESS_TOKEN, response.data.access)
        // update authorization header in auth requests
        updateBearerToken(response.data.access)
        resolve(response.data.access)
      })
      .catch(() => resolve(''))
  })
}

export const getApplicantPublic = (clientId) => {
  return new Promise((resolve, reject) => {
    APIRequest.get(`users/applicants/public/${clientId}/`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getActivePubs = (data) => {
  return new Promise((resolve, reject) => {
    APIRequest.get('users/pubs/', data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getEstablishment = (clientId) => {
  return new Promise((resolve, reject) => {
    APIRequest.get(`users/pubs/${clientId}/`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getVacanciesByClient = (clientId) => {
  return new Promise((resolve, reject) => {
    APIRequest.get(`users/vacancy/public/${clientId}/`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getRecentVacanciesByClient = (clientId) => {
  return new Promise((resolve, reject) => {
    APIRequest.get(`users/vacancy/public/${clientId}/recent/`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getVacancies = (data = null) => {
  const params = data ? new URLSearchParams(data) : []
  return new Promise((resolve, reject) => {
    APIRequest.get('users/vacancy/public/', { params })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getVacancy = (id) => {
  return new Promise((resolve, reject) => {
    APIRequest.get(`vacancies/${id}/`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const isCorrectRefreshError = (status) => status === 401
const isCorrectSubscriptionInvalidError = (status) => status === 402

const updateBearerToken = (token) => {
  AuthRequest.defaults.headers.Authorization = `Bearer ${token}`
  AuthRequestFormData.defaults.headers.Authorization = `Bearer ${token}`
}

/*
 * AuthRequest
 *
 * This refreshes the request and retries the token if it is invalid.
 * This is what you use to create any requests that need the Tokens.
 * Reference: https://hackernoon.com/110percent-complete-jwt-authentication-with-django-and-react-2020-iejq34ta
 *
 * Example:
 *     AuthRequest.get('/path/to/endpoint/',extraParameters)
 *        .then(response=>{
 *          // do something with successful request
 *        }).catch((error)=> {
 *          // handle any errors.
 *        })
 */
export const AuthRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`,
    'Content-Type': 'application/json',
  },
})

export const AuthRequestFormData = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`,
    'Content-Type': 'multipart/form-data',
  },
})

export const getClients = () => {
  return new Promise((resolve, reject) => {
    AuthRequest.get(`clients/`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * all Mailbox stuff always personalized on user
 */
export const getThreadWelcomeText = (clientId) => {
  return new Promise((resolve, reject) => {
    AuthRequest.get(`clients/establishment/${clientId}/thread_welcome_text/`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const getMail = () => {
  return new Promise((resolve, reject) => {
    AuthRequest.get('messaging/inbox/')
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const listThread = (uuid) => {
  return new Promise((resolve, reject) => {
    AuthRequest.get(`messaging/message/thread/${uuid}/`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const sendMail = (userId, data) => {
  return new Promise((resolve, reject) => {
    AuthRequest.post(`messaging/message/thread/${userId}/send/`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const answerMail = (userId, uuid, data) => {
  return new Promise((resolve, reject) => {
    AuthRequest.post(`messaging/message/thread/${uuid}/${userId}/send/`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const updateMail = (userId, thread, data) => {
  return new Promise((resolve, reject) => {
    AuthRequest.put(`messaging/message/thread/${userId}/${thread}/edit/`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const deleteMail = (uuid) => {
  return new Promise((resolve, reject) => {
    AuthRequest.delete(`messaging/thread/${uuid}/delete/`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// Menu Settings
export const fetchSettings = (id) => {
  return new Promise((resolve) => {
    AuthRequest.get(`clients/settings/${id}/`)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        // if (!isCorrectRefreshError(error.status)) {
        // }
        resolve('')
      })
  })
}
// User controls
export const fetchUser = (id) => {
  return new Promise((resolve) => {
    if (!id) {
      resolve('')
    }
    AuthRequest.get(`clients/${id}/`)
      .then((response) => {
        window.localStorage.setItem(ACCESS_TOKEN, response.data.tokens.access)
        window.localStorage.setItem(REFRESH_TOKEN, response.data.tokens.refresh)
        resolve(response.data.client)
      })
      .catch((error) => {
        // if (!isCorrectRefreshError(error.status)) {
        // }
        resolve('')
      })
  })
}

export const getApplicant = () => {
  return new Promise((resolve, reject) => {
    AuthRequest.get(`users/applicants/me/`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getApplicantBookmarks = () => {
  return new Promise((resolve, reject) => {
    AuthRequest.get(`users/applicants/me/bookmarks/`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getEstablishmentVacancies = () => {
  return new Promise((resolve, reject) => {
    AuthRequest.get('users/vacancies/')
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const addToApplicantBookmarks = (vacancyId) => {
  return new Promise((resolve, reject) => {
    AuthRequest.post(`users/applicants/me/bookmarks/${vacancyId}/`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const createVacancy = (data) => {
  return new Promise((resolve, reject) => {
    AuthRequest.post(`users/vacancy/`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const updateVacancy = (id, data) => {
  return new Promise((resolve, reject) => {
    AuthRequest.put(`users/vacancy/${id}/`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const deleteVacancy = (id) => {
  return new Promise((resolve, reject) => {
    AuthRequest.delete(`users/vacancy/${id}/`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const logoutUser = (triggerStore = false) => {
  window.localStorage.removeItem(ACCESS_TOKEN)
  window.localStorage.removeItem(REFRESH_TOKEN)
  AuthRequest.defaults.headers.Authorization = ''
  if (triggerStore) {
    store.commit('Auth/LOGOUT')
  }
  window.location.replace(process.env.VUE_APP_LANDING_PAGE_URL)
}

export const setPasswordUser = (password, token) => {
  return new Promise((resolve, reject) => {
    APIRequest.post('clients/password/', { password, token })
      .then((response) => {
        window.localStorage.setItem(ACCESS_TOKEN, response.data.tokens.access)
        window.localStorage.setItem(REFRESH_TOKEN, response.data.tokens.refresh)
        resolve(response.data.client)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const resetPasswordUser = (email) => {
  return new Promise((resolve, reject) => {
    APIRequest.put('clients/password/', { email })
      .then(() => {
        resolve()
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const deleteUser = (id) => {
  return new Promise((resolve, reject) => {
    AuthRequest.delete(`clients/${id}/`)
      .then(() => {
        resolve()
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const errorInterceptor = async (error) => {
  const originalRequest = error.config
  const { status } = error.response
  if (isCorrectRefreshError(status)) {
    if (originalRequest.retry) {
      logoutUser(true)
      return Promise.reject(new Error('Unauthenticated'))
    }
    const accessToken = await refreshToken()
    if (!accessToken) {
      // if token refresh fails, logout the user to avoid potential security risks.
      logoutUser(true)
      return Promise.reject(new Error('Unauthenticated'))
    }
    originalRequest.headers.Authorization = `Bearer ${accessToken}`
    originalRequest.retry = true
    return AuthRequest(originalRequest)
  }
  // // logout if subscription is invalid
  // if (isCorrectSubscriptionInvalidError(status)) {
  //   logoutUser(true)
  // }
  return Promise.reject(error)
}

AuthRequest.interceptors.response.use(
  (response) => response, // this is for all successful requests.
  (error) => errorInterceptor(error) // handle the request
)
AuthRequestFormData.interceptors.response.use(
  (response) => response, // this is for all successful requests.
  (error) => errorInterceptor(error) // handle the request
)
