const UI = {
  namespaced: true,
  state: () => ({
    barColor: '#303030',
    barImage: require('@/assets/sidebar.jpg'),
    drawer: undefined,
    // dialog
    dialog: {
      active: false,
      text: '',
      type: 'warning',
    },
    dialogPromise: undefined,
    dialogPromiseResolve: undefined,
    // snackbar
    snackbar: {
      active: false,
      text: '',
      timeout: 5000,
      type: 'success',
    },
    // mobile navigation one pager
    navWindow: 0,
  }),
  mutations: {
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload || '/'
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload
    },
    ACTIVATE_DIALOG(state, payload) {
      state.dialog.text = payload.text
      state.dialog.type = payload.type || 'warning'
      state.dialog.active = true
      // reset Promise resolve function
      state.dialogPromiseResolve = undefined
      // create new Promise
      state.dialogPromise = new Promise((resolve) => {
        state.dialogPromiseResolve = resolve
      })
    },
    ACTIVATE_SNACKBAR(state, payload) {
      state.snackbar.text = payload.text || ''
      state.snackbar.timeout = payload.persistent ? -1 : 5000
      state.snackbar.type = payload.type || 'success'
      state.snackbar.active = true
    },
    RESET_NAV_WINDOW(state) {
      state.navWindow = 0
    },
    DECREMENT_NAV_WINDOW(state) {
      if (state.navWindow > 0) {
        state.navWindow--
      } else {
        state.navWindow = 0
      }
    },
    INCREMENT_NAV_WINDOW(state) {
      state.navWindow++
    },
  },
  actions: {
    showDialog({ commit, state }, { text, type = 'warning' }) {
      commit('ACTIVATE_DIALOG', {
        text,
        type,
      })
      return state.dialogPromise
    },
    showSnackbar({ commit }, { text, type = 'success', persistent = false }) {
      commit('ACTIVATE_SNACKBAR', {
        text,
        type,
        persistent,
      })
    },
    navigateBack({ commit }) {
      commit('DECREMENT_NAV_WINDOW')
    },
    navigateForward({ commit }) {
      commit('INCREMENT_NAV_WINDOW')
    },
    navigateReset({ commit }) {
      commit('RESET_NAV_WINDOW')
    },
  },
  getters: {
    navWindow: (state) => {
      return state.navWindow
    },
  },
}

export default UI
