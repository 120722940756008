import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/index'
import { Role } from '@/store/modules/auth'
import { createRichResultJobPosting } from '@/services/common/vacancy'

Vue.use(Router)

const routes = [
  {
    path: '/',
    component: () => import('@/views/public/Index'),
    children: [
      // Landing page
      {
        name: 'landingPage',
        path: '',
        redirect: {
          name: 'search',
        },
      },
      // Search page
      {
        name: 'search',
        path: 'search',
        component: () => import('@/views/public/pages/SearchPage/index'),
        props: {
          redirect_to: true,
          q: true,
          l: true,
          r: true,
        },
        meta: {
          name: 'WIAB',
        },
      },
      // Establishment profile page
      {
        name: 'estProfile',
        path: 'establishment/:clientId',
        component: () => import('@/views/public/pages/ProfileEstablishmentPage/index'),
      },
      // User profile page
      {
        name: 'usrProfile',
        path: 'user/:clientId',
        component: () => import('@/views/public/pages/ProfileApplicantPage/index'),
        meta: {
          minRole: Role.est,
          allowOwnClientId: true,
        },
      },
      // Vacancy detail page
      {
        name: 'vacancyDetail',
        path: 'vacancy/:vacancyId',
        component: () => import('@/views/public/pages/VacancyDetailPage/index'),
      },
      // Legal pages
      {
        name: 'legal',
        path: '',
        component: () => import('@/views/public/pages/LegalPages/index'),
        children: [
          {
            name: 'privacy',
            path: 'privacy-policy',
            component: () => import('@/views/public/pages/LegalPages/PrivacyPolicy'),
          },
          {
            name: 'tos',
            path: 'terms-of-service',
            component: () => import('@/views/public/pages/LegalPages/TermsOfService'),
          },
          {
            name: 'tos_company',
            path: 'terms-of-service-company',
            component: () =>
              import('@/views/public/pages/LegalPages/TermsOfServiceCompany'),
          },
          {
            name: 'imprint',
            path: 'legal-notice',
            component: () => import('@/views/public/pages/LegalPages/LegalNotice'),
          },
        ],
      },
      {
        name: 'pricing',
        path: 'pricing',
        component: () => import('@/views/public/pages/PricingPage/Pricing.vue'),
      },
      {
        name: 'login',
        path: '/login',
        component: () => import('@/views/public/pages/LoginPage.vue'),
        meta: {
          publicOnly: true,
        },
      },
      {
        name: 'register',
        path: '/register',
        component: () => import('@/views/public/pages/RegisterPage/index'),
        meta: {
          publicOnly: true,
        },
      },
      // messaging redirect
      {
        name: 'messagingRedirect',
        path: 'messaging',
        redirect: {
          name: 'messaging',
        },
      },
      // // env
      // {
      //   name: 'env',
      //   path: 'env/CCMI1xP42t-Qz7Bf2wD8wkZ6C*Lj5oMe/',
      //   component: () => import('@/views/public/pages/EnvPage'),
      // },
    ],
  },
  {
    name: 'dashboardIndex',
    path: '/dashboard',
    component: () => import('@/views/dashboard/Index'),
    children: [
      // Dashboard
      {
        name: 'dashboard',
        path: '',
        redirect: {
          name: 'messaging',
        },
      },
      // Profile
      {
        name: 'profile',
        path: 'profile',
        component: () => import('@/views/dashboard/pages/ProfilePage'),
        meta: {
          name: 'Profile',
          minRole: Role.usr,
        },
      },
      // Messaging
      {
        name: 'messaging',
        path: 'messaging/:to?',
        component: () => import('@/views/dashboard/pages/MessagingPage'),
        props: true,
        meta: {
          name: 'Messaging',
          minRole: Role.usr,
        },
      },
      // Vacancies
      {
        name: 'vacancies',
        path: 'vacancies',
        component: () => import('@/views/dashboard/pages/VacanciesPage'),
        meta: {
          name: 'Vacancies',
          minRole: Role.est,
        },
      },
      // Bookmarks
      {
        name: 'bookmarks',
        path: 'bookmarks',
        component: () => import('@/views/dashboard/pages/BookmarksPage'),
        meta: {
          name: 'Bookmarks',
          minRole: Role.usr,
          exactRole: true,
        },
      },
    ],
  },
  {
    name: 'password',
    path: '/password',
    component: () => import('@/views/public/pages/PasswordResetPage.vue'),
    meta: {
      publicOnly: true,
    },
  },
  {
    path: '*',
    redirect: '/',
  },
]

const router = new Router({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes: routes,
})

router.beforeEach((to, from, next) => {
  /**
   * Authentication
   */
  // check if accessible when authenticated
  const isAuthenticated = store.state.Auth.isAuthenticated
  const publicOnly = to.meta?.publicOnly
  if (isAuthenticated && publicOnly) {
    // deny access
    next(from.path)
    return
  }
  // check role
  const role = store.state.Auth.role
  const minRole = to.meta?.minRole

  if (
    (role !== undefined && minRole !== undefined && role > minRole) ||
    (to.meta?.exactRole && role !== minRole)
  ) {
    // deny access
    next(from.path)
    return
  }
  // check if accessible when unauthenticated
  if (isAuthenticated === false && minRole !== undefined) {
    // deny access
    next({
      name: 'login',
      query: { redirect_to: to.fullPath },
    })
    return
  }
  /**
   * Google Rich Results
   */
  if (to.name === 'vacancyDetail') {
    createRichResultJobPosting(to.params.vacancyId)
  }
  // accept navigation
  next()
})

export default router
