import { getEstablishment, getVacancy } from '@/services/api/auth'

/**
 * Return a one liner with core vacancy information.
 */
export function getShortInfo(vacancy) {
  let txt = ''
  const bullet = '<div class="divider-bullet"></div>'
  if (vacancy.est?.administrative_area_level_3) {
    if (txt) {
      txt += bullet
    }
    txt += vacancy.est.administrative_area_level_3
  }
  if (vacancy.employment_type) {
    // TODO
    // quickfix
    let wh = vacancy.employment_type
    wh = wh.replace('_', ' ')
    wh = wh.charAt(0).toUpperCase() + wh.substring(1)
    // end quickfix
    if (txt) {
      txt += bullet
    }
    txt += wh
  }
  if (vacancy.salary_expectations) {
    if (txt) {
      txt += bullet
    }
    txt += vacancy.salary_expectations
  }
  return txt
}

/**
 * Create structured data to the page head to implement Google Rich Results for vacancies.
 *
 * Ref: https://developers.google.com/search/docs/appearance/structured-data/job-posting
 */
export async function createRichResultJobPosting(vacancyId) {
  if (!vacancyId) {
    return
  }
  const response = await getVacancy(vacancyId)
  if (!response || response.status !== 200) {
    return
  }
  const vacancy = response.data
  if (!vacancy || !vacancy.active) {
    return
  }
  const establishment = await getEstablishment(vacancy.client)
  if (!establishment) {
    return
  }
  const nowISO = new Date().toISOString()
  if (!vacancy.active || vacancy.expiration_date < nowISO) {
    // cancel if vacancy is inactive or expired
    return
  }
  const script = document.createElement('script')
  script.type = 'application/ld+json'
  // TODO baseSalary
  let payload = {
    '@context': 'https://schema.org/',
    '@type': 'JobPosting',
    datePosted: vacancy.created,
    description: vacancy.description,
    employmentType: vacancy.employment_type.replaceAll('_', '-'),
    hiringOrganization: {
      '@type': 'Organization',
      // TODO needs to be organization name
      // https://developers.google.com/search/docs/appearance/structured-data/job-posting#structured-data-type-definitions
      name: vacancy.est.restaurant_name,
    },
    jobLocation: {
      '@type': 'Place',
      address: {
        '@type': 'PostalAddress',
        addressCountry: vacancy.est.country,
        addressLocality: vacancy.est.city,
        postalCode: vacancy.est.zip,
        streetAddress: `${vacancy.est.street} ${vacancy.est.house_number}`,
      },
    },
    title: vacancy.title,
    validThrough: vacancy.expiration_date,
  }
  // add `sameAs` to `hiringOrganization`
  if (establishment.website) {
    payload.hiringOrganization.sameAs = establishment.website
  }
  // add `logo` to `hiringOrganization`
  if (establishment.logo) {
    payload.hiringOrganization.logo = process.env.VUE_APP_FILES_BASE_URL + establishment.logo
  }
  script.text = JSON.stringify(payload)
  document.head.appendChild(script)
}
