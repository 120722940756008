import Vue from 'vue'
import Vuex from 'vuex'

import Auth from './modules/auth'
import Client from './modules/client'
import UI from './modules/ui'
import Users from './modules/users'
import Mail from './modules/mail'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Auth,
    Client,
    UI,
    Users,
    Mail,
  },
  state: {},
  mutations: {},
  actions: {},
})
