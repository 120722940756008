import {
  deleteUser,
  loginUser,
  fetchUser,
  logoutUser,
  setPasswordUser,
  resetPasswordUser,
  refreshToken,
  REFRESH_TOKEN,
} from '@/services/api/auth'
import jwtDecode from 'jwt-decode'

const Role = {
  est: 0,
  usr: 1,
}

const getUserRole = function (user) {
  return !user?.establishment ? Role.usr : Role.est
}

const Auth = {
  namespaced: true,
  state: () => ({
    role: undefined,
    clientId: '',
    isAuthenticated: null,
  }),
  mutations: {
    SET_ROLE(state, data) {
      state.role = data
    },
    SET_USER_ID(state, data) {
      state.clientId = data
    },
    SET_IS_AUTHENTICATED(state, data) {
      state.isAuthenticated = data
    },
    LOGIN(state, data) {
      state.role = getUserRole(data)
      state.clientId = data.id
      state.isAuthenticated = true
    },
    LOGOUT(state) {
      state.role = undefined
      state.clientId = ''
      state.isAuthenticated = false
    },
  },
  actions: {
    async authenticate({ commit }) {
      if (!window.localStorage.getItem(REFRESH_TOKEN)) {
        commit('LOGOUT')
        return false
      }
      const accessToken = await refreshToken()
      if (!accessToken) {
        commit('LOGOUT')
        return false
      }
      const token = jwtDecode(accessToken)
      const response = await fetchUser(token.client_id)
      if (!response) {
        commit('LOGOUT')
        return false
      }
      // check if subscription is valid
      // const subscriptionStatus = response.stripe_subscription_status || ''
      // if (!['active', 'trialing', 'canceling'].includes(subscriptionStatus)) {
      //   commit('LOGOUT')
      //   return false
      // }
      // client successfully authenticated
      const role = getUserRole(response)
      commit('SET_IS_AUTHENTICATED', true)
      commit('SET_ROLE', role)
      commit('SET_USER_ID', response.id)
      commit('Client/INITIALIZE', response, { root: true })
      return true
    },
    login({ commit }, { email, password }) {
      return new Promise((resolve, reject) => {
        loginUser(email, password)
          .then((data) => {
            // window.location.replace('/')
            // commit('LOGIN', data)
            // commit('Client/INITIALIZE', data, { root: true })
            resolve()
          })
          .catch((error) => {
            commit({ type: 'LOGOUT' })
            reject(error)
          })
      })
    },
    logout({ commit }) {
      commit('LOGOUT')
      logoutUser()
    },
    setPassword({ commit }, { password, token }) {
      return new Promise((resolve, reject) => {
        setPasswordUser(password, token)
          .then((data) => {
            commit({ type: 'LOGIN', data })
            resolve()
          })
          .catch((error) => {
            commit({ type: 'LOGOUT' })
            reject(error)
          })
      })
    },
    resetPassword(_, email) {
      return new Promise((resolve, reject) => {
        resetPasswordUser(email)
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteClient({ state }) {
      return new Promise((resolve, reject) => {
        deleteUser(state.clientId)
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
  getters: {
    isRoleEst: (state) => {
      return state.role === Role.est
    },
  },
}

export default Auth
export { Role }
