import prettyBytes from 'pretty-bytes'

const Client = {
  namespaced: true,
  state: () => ({
    activePubs: [],
    vacancies: [],
    myVacancies: [],
    specialDialog: { bool: false, data: {} },
    items: [],
    estImage: '',
  }),
  getters: {
    getActivePubs: (state) => {
      return state.activePubs
    },
    getVacancies: (state) => {
      return state.vacancies
    },
    getMyVacancies: (state) => {
      return state.myVacancies
    },
    getSpecialDialog: (state) => {
      return state.specialDialog
    },
    getEstImageUri: (state) => {
      const url = state.estImage.est_image
      return state.estImage ? process.env.VUE_APP_FILES_BASE_URL + url : ''
    },
    getEstImageInfo: (state) => (file) => {
      if (!file) {
        return ''
      }
      const size = file.size || 0
      let name = file.name || ''
      const len = 50
      if (name.length > len) {
        const md = Math.floor(len / 2)
        const n0 = name.substring(0, md)
        const n1 = name.substring(name.length - md)
        name = n0 + '...' + n1
      }
      return name + ' (' + prettyBytes(size) + ')'
    },
  },
  mutations: {
    INITIALIZE_PUB_VACANCIES(state, payload) {
      state.items = []
      for (const item of payload) {
        state.items.push(item)
      }
    },

    INITIALIZE_ACTIVE_PUBS(state, payload) {
      state.activePubs = payload
    },
    INITIALIZE_VACANCIES(state, payload) {
      state.vacancies = payload
    },
    INITIALIZE_ESTIMAGE(state, payload) {
      state.estImage = payload
    },
    INITIALIZE_MY_VACANCIES(state, payload) {
      state.myVacancies = payload
    },
    INITIALIZE_SPECIAL_DIALOG(state, payload) {
      state.specialDialog = payload
    },
    UPDATE_SPECIAL_DIALOG(state, payload) {
      if (payload.bool === true) {
        state.specialDialog = true
      } else {
        state.specialDialog = false
      }
    },
  },
  actions: {
    initialize_active_pubs: (context, data) => {
      context.commit('INITIALIZE_ACTIVE_PUBS', data)
    },
    initialize_vacancies: (context, data) => {
      context.commit('INITIALIZE_VACANCIES', data)
    },
    initialize_est_image: (context, data) => {
      context.commit('INITIALIZE_ESTIMAGE', data)
    },
    initialize_my_vacancies: (context, data) => {
      context.commit('INITIALIZE_MY_VACANCIES', data)
    },
    initialize_sepcial_dialog: (context, data) => {
      context.commit('INITIALIZE_SPECIAL_DIALOG', data)
    },
    initializePubVacancies: (context, data) => {
      context.commit('INITIALIZE_PUB_VACANCIES', data)
    },
  },
}

export default Client
