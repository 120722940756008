import prettyBytes from 'pretty-bytes'
import { plans } from '@/services/api/payment.js'

const formatDate = {
  month: 'short',
  day: 'numeric',
}
const formatterDate = new Intl.DateTimeFormat('de-DE', formatDate)

const Client = {
  namespaced: true,
  state: () => ({
    id: '',
    est_id: '',
    owner_first_name: '',
    owner_last_name: '',
    restaurant_name: '',
    street: '',
    house_number: '',
    zip: '',
    city: '',
    country: '',
    tel: '',
    email: '',
    website: '',
    profileDescription: '',
    est_type: '',
    logo: '',
    lat: '',
    lng: '',
    qr: '',
    subscription_id: '',
    subscription_package: '',
    subscription_status: '',
    subscription_trial_end: null,
    vacancy_contingent: 0,
    menuSettings: {
      darkTheme: false,
      accentColor: '#9C27b0',
      notifications: true,
    },
  }),
  mutations: {
    INITIALIZE(state, payload) {
      // client info
      state.id = payload.id
      state.owner_first_name = payload.owner_first_name
      state.owner_last_name = payload.owner_last_name
      state.email = payload.email
      state.tel = payload.tel
      state.website = payload.website
      state.logo = payload.logo
      state.qr = payload.qr_code
      // establishment info
      state.est_id = payload.establishment?.id
      state.restaurant_name = payload.establishment?.restaurant_name
      state.street = payload.establishment?.street
      state.house_number = payload.establishment?.house_number
      state.zip = payload.establishment?.zip
      state.city = payload.establishment?.city
      state.country = payload.establishment?.country
      state.lat = payload.establishment?.lat
      state.lng = payload.establishment?.lng
      state.profileDescription =
        payload.establishment?.profileDescription || payload.profileDescription
      state.est_type = payload.establishment?.est_type
      state.subscription_id = payload.establishment?.subscription_id
      state.subscription_package = payload.establishment?.subscription_plan
      state.subscription_status = payload.establishment?.subscription_status
      state.subscription_trial_end = payload.establishment?.subscription_trial_end
      state.vacancy_contingent = payload.establishment?.vacancy_contingent
    },
    SET_VALUES(state, payload) {
      for (const key in payload) {
        if (state[key] !== undefined) {
          state[key] = payload[key]
        }
      }
    },
    INITIALIZE_SETTINGS(state, payload) {
      state.menuSettings = {
        darkTheme: payload.darkTheme,
        accentColor: payload.accentColor,
        notifications: payload.notifications,
      }
    },
  },
  actions: {
    initializeClient: (context, data) => {
      context.commit('INITIALIZE', data)
    },
    updateClient: (context, data) => {
      context.commit('SET_VALUES', data)
    },
    initializeSettings: (context, data) => {
      context.commit('INITIALIZE_SETTINGS', data)
    },
  },
  getters: {
    getFullName: (state) => {
      return `${state.owner_first_name} ${state.owner_last_name}`
    },
    getMenuSettings: (state) => {
      return state.menuSettings
    },
    getLogoUri: (state) => {
      return state.logo ? process.env.VUE_APP_FILES_BASE_URL + state.logo : ''
    },
    getQRUri: (state) => {
      return state.qr ? process.env.VUE_APP_FILES_BASE_URL + state.qr : ''
    },
    getLogoInfo: (state) => (file) => {
      if (!file) {
        return ''
      }
      const size = file.size || 0
      let name = file.name || ''
      const len = 50
      if (name.length > len) {
        const md = Math.floor(len / 2)
        const n0 = name.substring(0, md)
        const n1 = name.substring(name.length - md)
        name = n0 + '...' + n1
      }
      return name + ' (' + prettyBytes(size) + ')'
    },
    isSubscriptionValid: (state) => {
      return ['active', 'trialing', 'canceling'].includes(state.subscription_status)
    },
    getSubscriptionID: (state) => {
      return state.subscription_id
    },
    getSubscriptionStatus: (state) => {
      return state.subscription_status
    },
    getSubscriptionPackage: (state) => {
      return plans[state.subscription_package]?.displayNameLocaleCode
    },
    getSubscriptionTrialEnd: (state) => {
      if (state.subscription_status !== 'trialing') {
        return ''
      }
      const time = state.subscription_trial_end * 1000
      if (time < Date.now()) {
        return ''
      }
      return formatterDate.format(time)
    },
    getSubscriptionNextBillingCycle: (state) => {
      return this.subscriptionNextBillingCycle
        ? formatterDate.format(this.subscriptionNextBillingCycle)
        : ''
    },
    getVacancyContingent: (state) => {
      return state.vacancy_contingent
    },
  },
}

export default Client
