<template>
  <v-container
    id="container"
    fluid
    :style="{ height: $vuetify.breakpoint.mdAndUp ? '5rem' : '10rem' }"
    :class="{ 'd-none': !showCC }"
  >
    <v-row class="d-flex justify-center align-center">
      <v-col
        cols="12"
        sm="auto"
      >
        {{ $t('this_website_uses_cookies_to_ensure_you_the_best_experience') }}
        <router-link
          :to="{
            name: 'privacy',
          }"
        >
          {{ $t('learn_more') }}
        </router-link>
      </v-col>
      <v-col
        cols="12"
        sm="auto"
      >
        <v-btn
          color="primary"
          class="mx-0 ml-md-8"
          @click="acceptConsent"
        >
          {{ $t('got_it') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'CookieBanner',
  props: {
    showCC: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    acceptConsent() {
      // set cookie
      const d = new Date()
      d.setFullYear(d.getFullYear() + 3)
      const secure = process.env.NODE_ENV !== 'development' ? 'secure' : ''
      document.cookie = `wiabCC=true; expires=${d.toUTCString()}; path=/; sameSite=Lax; ${secure}`
      // hide banner
      this.$emit('update:showCC', false)
    },
  },
}
</script>

<style lang="scss" scoped>
#container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  background-color: #ddd;
  z-index: 1000;
}
</style>
