import Vue from 'vue'

const Mail = {
  namespaced: true,
  state: () => ({
    inbox: {},
    count: 0,
    pagination: null,
    messages: [],
    subject: '',
    senderLogo: '',
  }),
  getters: {
    getMailInbox: (state) => {
      return state.inbox.results
    },
    getPagination: (state) => {
      return state.pagination
    },
    listThread: (state) => {
      return state.messages
    },
    getNotificationCount: (state) => {
      let count = 0
      for (const index in state.inbox.results) {
        const controlDate = state.inbox.results[index].control_date
        const sentAtLatest = state.inbox.results[index].sent_at_latest
        const totalUnread = state.inbox.results[index].total_unread
        if (totalUnread && controlDate < sentAtLatest) {
          count++
        }
      }
      return count
    },
  },
  mutations: {
    INITIALIZE_MAIL(state, payload) {
      state.inbox = payload
      let count = 0
      for (const index in state.inbox.results) {
        const controlDate = state.inbox.results[index].control_date
        const sentAtLatest = state.inbox.results[index].sent_at_latest
        const totalUnread = state.inbox.results[index].total_unread
        if (totalUnread && controlDate < sentAtLatest) {
          count++
        }
      }
      state.count = count
    },
    INITIALIZE_COUNT(state, payload) {
      state.count = payload
    },
    INITIALIZE_PAGINATION(state, payload) {
      state.pagination = payload
    },
    INITIALIZE_THREAD(state, payload) {
      state.messages = payload
      state.subject = payload
      state.thread = payload
      for (const index in state.inbox.results) {
        if (
          state.inbox.results[index].sender.user_id ===
          payload.messages[index].sender.user_id
        ) {
          state.senderLogo = state.inbox.results[index].owner_information.logo
        }
        return state.senderLogo
      }
    },
    SET_CLICK(state, payload) {
      for (const index in state.inbox.results) {
        if (state.inbox.results[index].uuid === payload.uuid) {
          state.inbox.results[index].control_date = payload.control_date
          if (state.count !== 0) {
            state.count -= 1
          }
        }
      }
    },
    SET_SENT(state, payload) {
      for (const index in state.inbox.results) {
        if (state.inbox.results[index].uuid === payload.uuid) {
          const results = state.inbox.results
          results[index].total_unread = payload.total_unread
          Vue.set(state.inbox, 'results', results)
        }
      }
    },
    DELETE_RESULT(state, payload) {
      Vue.set(
        state.inbox,
        'results',
        state.inbox.results.filter((el, index) => index !== payload)
      )
    },
  },
  actions: {
    initialize_mail: (context, data) => {
      context.commit('INITIALIZE_MAIL', data)
    },
    initialize_count: (context, data) => {
      context.commit('INITIALIZE_COUNT', data)
    },
    initialize_pagination: (context, data) => {
      context.commit('INITIALIZE_PAGINATION', data)
    },
    initialize_thread: (context, data) => {
      context.commit('INITIALIZE_THREAD', data)
    },
    delete_result: (context, data) => {
      context.commit('DELETE_RESULT', data)
    },
    update_inbox_count: (context, data) => {
      context.commit('SET_CLICK', data)
    },
    update_response: (context, data) => {
      context.commit('SET_SENT', data)
    },
  },
}

export default Mail
