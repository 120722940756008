<template>
  <v-container
    id="container"
    fluid
  >
    <v-row
      justify="center"
      align="center"
    >
      <v-col
        cols="auto"
        class="pa-0"
      >
        <v-window
          v-model="window"
          touchless
        >
          <v-window-item>
            <v-card
              max-width="400"
              width="90vw"
              class="mx-4 mt-5"
            >
              <v-card-title class="py-4">
                <div class="text-h4 font-weight-bold primary--text">
                  {{ $t('login') }}
                </div>
              </v-card-title>
              <v-card-text>
                <v-form ref="form">
                  <v-text-field
                    v-model="email"
                    prepend-icon="mdi-account-circle"
                    validate-on-blur
                    type="email"
                    tabindex="1"
                    :label="$t('email')"
                    :rules="emailRules"
                  />
                  <v-text-field
                    v-model="password"
                    :type="showPassword ? 'text' : 'password'"
                    prepend-icon="mdi-lock"
                    tabindex="2"
                    :label="$t('password')"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="passwordRules"
                    @keydown.enter="loginClient"
                    @click:append="showPassword = !showPassword"
                  />
                  <div
                    v-if="errorMsg"
                    class="text-caption red--text text--darken-4 text-center"
                  >
                    {{ $t(errorMsg) }}
                  </div>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-row
                  justify="center"
                  class="text-center"
                >
                  <v-col cols="auto">
                    <v-btn
                      color="primary"
                      tabindex="3"
                      class="mx-0"
                      :loading="loadingForm"
                      :disabled="loadingForm"
                      @click="loginClient"
                    >
                      Login
                    </v-btn>
                    <br />
                    <v-btn
                      text
                      small
                      class="text-caption text--secondary mx-0 mt-1"
                      @click="window = 1"
                    >
                      {{ $t('forgot_your_password?') }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-window-item>
          <v-window-item>
            <v-card
              max-width="450"
              width="90vw"
              class="mx-4 mt-5"
            >
              <v-card-title class="py-4">
                <v-btn
                  class="mr-1 mr-sm-2"
                  text
                  icon
                  @click="window = 0"
                >
                  <v-icon> mdi-arrow-left </v-icon>
                </v-btn>
                <div class="text-h4 font-weight-bold primary--text">
                  {{ $t('reset_password') }}
                </div>
              </v-card-title>
              <v-card-subtitle v-if="!requestSent">
                <div class="text-body-2 mt-2">
                  {{ $t('we_send_you_a_reset_password_request_via_email_immediately') }}
                </div>
              </v-card-subtitle>
              <v-divider v-if="requestSent" />
              <v-card-text>
                <v-form
                  v-if="!requestSent"
                  ref="formReset"
                >
                  <v-text-field
                    v-model="email"
                    prepend-icon="mdi-account-circle"
                    validate-on-blur
                    type="email"
                    :label="$t('email')"
                    :rules="emailRules"
                  />
                  <div
                    v-if="errorMsgReset"
                    class="text-caption red--text text--darken-4 text-center"
                  >
                    {{ $t(errorMsgReset) }}
                  </div>
                </v-form>
                <div
                  v-else
                  class="text-center"
                >
                  <p>
                    {{
                      $t(
                        'an_email_with_instructions_to_reset_your_password_will_be_sent_to'
                      )
                    }}<span class="font-weight-bold">{{ email }}</span
                    >.
                  </p>
                  <p>{{ $t('it_can_last_up_to_a_few_minutes') }}</p>
                  <p class="text-body-2">
                    {{ $t('please_also_check_your_spam_folder') }}
                  </p>
                </div>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  class="mx-0"
                  :loading="loadingFormReset"
                  :disabled="loadingFormReset || requestSent"
                  @click="passwordResetClient"
                >
                  {{ $t('reset') }}
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data: () => {
    return {
      window: 0,
      requestSent: false,
      // form
      showPassword: false,
      email: '',
      password: '',
      errorMsg: '',
      errorMsgReset: '',
      loadingForm: false,
      loadingFormReset: false,
      emailRules: [
        (v) => !!v || 'Please type in a valid email',
        (v) => v.length <= 255 || 'This field can have at most 255 characters',
        (v) => /.+@.+\...+/.test(v) || 'Please type in a valid email',
      ],
      passwordRules: [(v) => !!v || 'Please type in a valid password'],
    }
  },
  methods: {
    loginClient() {
      // validate
      if (!this.$refs.form.validate()) {
        // cancel form submission
        return
      }
      this.loadingForm = true
      this.login({ email: this.email, password: this.password })
        .then(() => {
          if (
            !this.$route.matched.length ||
            this.$route.matched.some((route) => route.name === 'login')
          ) {
            if (this.$route.query?.redirect_to) {
              // redirect to requested path
              window.location.replace(this.$route.query.redirect_to)
            } else {
              // after normal login from the login page, redirect to the dashboard
              window.location.replace('/dashboard')
            }
          } else {
            // load the requested url, if login page was shown after an unauthenticated request
            window.location.reload()
          }
        })
        .catch(() => {
          this.errorMsg = 'invalid_login_data'
        })
        .finally(() => {
          this.loadingForm = false
        })
    },
    passwordResetClient() {
      this.loadingFormReset = true
      this.resetPassword(this.email)
        .then(() => {
          this.requestSent = true
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            this.errorMsgReset = 'email_not_in_db'
          } else {
            this.errorMsgReset = 'password_reset_failed'
          }
        })
        .finally(() => {
          this.loadingFormReset = false
        })
    },
    ...mapActions('Auth', ['login', 'resetPassword']),
  },
}
</script>

<style scoped>
#container {
  display: flex;
  align-items: center;
  height: 100vh;
}
</style>
