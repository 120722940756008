<template>
  <v-app>
    <page-loader v-if="isLoading" />
    <login-page v-else-if="showLogin" />
    <template v-else>
      <router-view :style="{ marginBottom: getMarginBottom }" />
      <cookie-banner :showCC.sync="showCC" />
    </template>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import { getCookie } from '@/services/common/common'

import PageLoader from '@/components/PageLoader.vue'
import CookieBanner from '@/components/CookieBanner.vue'
import LoginPage from '@/views/public/pages/LoginPage.vue'

export default {
  name: 'App',
  components: {
    PageLoader,
    CookieBanner,
    LoginPage,
  },
  data: () => {
    return {
      isLoading: true,
      showLogin: true,
      promiseInit: null,
      showCC: false,
    }
  },
  computed: {
    ...mapState('Auth', ['isAuthenticated']),
    getMarginBottom() {
      return !this.showCC ? '0' : this.$vuetify.breakpoint.mdAndUp ? '5rem' : '10rem'
    },
  },
  created() {
    // check consent cookie
    const cookie = getCookie('wiabCC')
    if (!cookie || cookie !== 'true') {
      this.showCC = true
    }
  },
  beforeMount() {
    this.promiseInit = new Promise((resolve, reject) => {
      this.$store.dispatch('Auth/authenticate').then(() => {
        // if the Vue router is not ready yet, use a Vue watcher
        if (!this.$route.name) {
          const unwatch = this.$watch('$route', (route) => {
            const initRes = this.init()
            // delete this watcher
            unwatch()
            initRes ? resolve() : reject(new Error())
          })
        } else {
          this.init() ? resolve() : reject(new Error())
        }
      })
    })
  },
  mounted() {
    this.promiseInit
      .then(() => {
        this.isLoading = false
      })
      .catch(() => {
        // Access to requested url denied.
        if (!this.showLogin) {
          // Do not remove the `isLoading` flag to prevent showing fragments of the requested page.
          return
        }
        // show login page
        this.isLoading = false
      })
  },
  methods: {
    init() {
      // process after authentication
      if (!this.isAuthenticated) {
        if (this.$route.meta?.minRole !== undefined) {
          // deny access
          return false
        }
      } else if (this.$route.meta?.publicOnly) {
        // deny access
        window.location.replace(process.env.VUE_APP_LANDING_PAGE_URL)
        this.showLogin = false
        return false
      }
      this.showLogin = false
      return true
    },
  },
}
</script>

<style lang="scss">
html {
  overflow-y: auto !important;
}

section {
  padding-top: 2.5rem;
  background-color: #fafafa;

  &.dense {
    padding-top: 0;
  }

  &.last {
    padding-bottom: 2.5rem;
  }
}

a.plain {
  text-decoration: none;
  color: inherit !important;

  &:hover {
    cursor: pointer;
  }
}

.html {
  padding: 3rem 0;
}

.text-break-normal {
  word-break: normal;
}

.mb-mobile {
  margin-bottom: 3rem !important;
}

.v-card {
  > .profile-banner-container {
    > :first-child {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }
  }
}

.profile-banner-container {
  margin-bottom: 4rem;

  .profile-img-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.mobile {
      flex-direction: column;

      > :first-child {
        flex-direction: column;
        text-align: center;

        .profile-img {
          margin-left: 0 !important;
        }

        .profile-title {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-left: 0;

          > * {
            display: block;
          }
        }
      }

      > :last-child {
        margin-top: 2rem;
      }
    }

    > :first-child {
      flex-grow: 1;
      display: flex;
      align-items: center;
    }

    .profile-title {
      margin-left: 2rem;
    }
  }

  .profile-img {
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: -8.5rem;
    margin-left: 10%;
    border-radius: 1rem;
    border: 5px solid #f7f7f7;
    background-color: #67a0a5;
  }
}

.btn-square {
  background-color: transparent !important;
  border-radius: 0.5rem;
  border: 1px solid #bfbfbf;
}

.divider-bullet {
  display: inline-block;
  margin: 0 0.5rem;

  &:before {
    content: '•';
  }
}

.detail-overview {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 2rem;
  padding: 0 !important;
  margin: 2rem 0;
  list-style: none;
  border-radius: 1rem;
  border: 1px solid #bebebe;

  &.mobile {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;

    li {
      &:not(:first-child) {
        border-left: none;
        border-top: 1px solid #bebebe;
      }
    }
  }

  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    text-align: center;

    &:not(:first-child) {
      border-left: 1px solid #bebebe;
    }

    .v-icon {
      margin-bottom: 0.5rem;
    }
  }
}

.map-container {
  margin-top: 2rem;
  height: 35rem;
}

.ratings-container {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  .ratings-heading {
    display: flex;
    justify-content: space-between;
  }

  .ratings-overview {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 10rem;
    row-gap: 1.5rem;
    margin-top: 2rem;
    padding: 0 2rem;

    > * {
      display: flex;
      justify-content: space-between;
    }
  }

  .ratings-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 10rem;
    row-gap: 1.5rem;
    margin-top: 5rem;
  }
}
</style>
