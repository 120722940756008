<template>
  <v-dialog
    v-model="dialog"
    v-bind="{
      ...$attrs,
    }"
    persistent
    transition="slide-y-transition"
    width="500px"
    max-width="90%"
  >
    <v-card>
      <v-card-title class="text-headline warning"></v-card-title>

      <v-card-text class="text-justify text-h5 pt-4">
        <slot />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="cancel"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          text
          color="success"
          @click="accept"
        >
          {{ $t('ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'BaseMaterialDialog',
  props: ['value', 'type'],
  data: () => ({}),
  computed: {
    ...mapState('UI', ['dialogPromiseResolve']),
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  created() {},
  methods: {
    accept() {
      this.dialog = false
      this.dialogPromiseResolve(true)
    },
    cancel() {
      this.dialog = false
      this.dialogPromiseResolve(false)
    },
  },
}
</script>

<style lang="scss" scoped></style>
